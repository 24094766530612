<template>
  <section class="mb-2">
    <h1
      class="font-weight-light mb-2 headline text-center"
      v-text="`${heading}`"
    />
    <template v-if="showback">
      <span
        class="font-weight-light subtitle-1"
      >
        <v-btn
          elevation="1"
          color="blue-grey"
          dark
          @click="goBack"
        >
          <v-icon
            color="white"
          >
            mdi-keyboard-backspace
          </v-icon>
          Back
        </v-btn>
      </span>
    </template>
  </section>
</template>

<script>
  import { navigationMixin } from '@/mixins/navigationMixin.js'
  export default {
    name: 'VComponent',
    mixins: [navigationMixin],
    props: {
      heading: {
        type: String,
        default: '',
      },
      link: {
        type: String,
        default: '',
      },
      showback: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>
