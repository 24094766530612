import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import './plugins/vue-world-map'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import JsonExcel from 'vue-json-excel'
import VueClipboard from 'vue-clipboard2'
import i18n from './i18n'
import { Notifier } from '@airbrake/browser'
import ActionCableVue from 'actioncable-vue'
import Constants from '@/plugins/constants'
import userflow from 'userflow.js'

Vue.use(ActionCableVue, {
  debug: true,
  debugLevel: 'error',
  connectImmediately: false,
  store,
})

var airbrake = new Notifier({
  environment: 'production',
  projectId: 271368,
  projectKey: 'd1eb352ac646752acdd318708d9f36c6',
})

Vue.config.errorHandler = function (err, vm, info) {
  airbrake.notify({
    error: err,
    params: { info: info },
  })
}

Vue.config.productionTip = false
Vue.component('downloadExcel', JsonExcel)
Vue.use(VueClipboard)
Vue.use(Constants)
Vue.use(userflow)
userflow.init('ct_cnc34asrprfq5nqiltf5gowlt4')


new Vue({
  router,
  store,
  vuetify,
  i18n,
  created () {
    const userString = localStorage.getItem('user')
    if (userString) {
      const userData = JSON.parse(userString)
      this.$store.commit('SET_USER_DATA', userData)
    }
    axios.defaults.baseURL =
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:3000'
        : 'https://dealer-insights-api.herokuapp.com/'
  },
  // watch: {
  //   "$route": () => {
  //     window.Appcues.page()
  //   }
  // },
  render: h => h(App),
}).$mount('#app')

export default axios
