const Constants = {
  install (Vue, options) {
    Vue.Constants = {
      VERSION: 1.0,
      AC_TITLE: 'Attempting Contact',
      DI_TITLE: 'Dealer Insights',
    }
  },
}

export default Constants
