<template>
  <router-view />
</template>

<script>
  export default {
    name: 'App',
    channels: {
      ReportChannel: {
        received (data) {
          // console.log('receiving data')
          // console.log(data)
          this.$store.dispatch('set_report_data', { reportType: data.report_type, cacheKey: data.cache_key, campaignId: data.campaign_id, fileName: data.filename })
        },
      },
      AccountSetupChannel: {
        received (data) {
          this.$store.dispatch('set_account_setup_data', data)
        },
      },
      ChatovateInboundCallChannel: {
        received (data) {
          this.$store.dispatch('set_chatovate_inbound_call', data)
        },
      },      
      ChatovateSmsChannel: {
        received (data) {
          this.$store.dispatch('set_chatovate_sms', data)
        },
      },
      ChatovateSentSmsChannel: {
        received (data) {
          this.$store.dispatch('set_chatovate_sent_sms', data)
        },
      },
      ReleaseChannel: {
        received (data) {
          this.$store.dispatch('set_release', data)
        },
      },
      OpenAiDescriptionChannel: {
        received (data) {
          this.$store.dispatch('set_vehicle_description', data)
        },
      },
    },
  }
</script>
