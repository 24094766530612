import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/pages',
      component: () => import('@/views/pages/Index'),
      children: [
        {
          name: 'Lock',
          path: 'lock',
          component: () => import('@/views/pages/Lock'),
        },
        {
          name: 'Pricing',
          path: 'pricing',
          component: () => import('@/views/pages/Pricing'),
        },
      ],
    },
    {
      path: '/login',
      component: () => import('@/views/pages/Index'),
      children: [
        {
          name: 'Login',
          path: '/login',
          component: () => import('@/views/Login'),
        },
        {
          name: 'AC Login',
          path: '/ac_login',
          component: () => import('@/views/ACLogin'),
        },
        {
          name: 'Register',
          path: '/register',
          component: () => import('@/views/Register'),
        },
      ],
    },
    {
      path: '/reports/',
      component: () => import('@/views/dashboard/PrintableIndex'),
      children: [
        {
          name: 'Campaign Results',
          path: '/reports/:mail_campaign_id/campaign_results',
          component: () => import('@/views/dashboard/CampaignReport'),
          meta: { requiresAuth: false },
        },
        {
          name: 'KAG Sales DAR',
          path: '/reports/kag_sales_dar',
          component: () => import('@/views/reports/KagSalesDar'),
          meta: { requiresAuth: false },
        },
        {
          name: 'KAG Sales DAR Store Summary',
          path: '/reports/kag_sales_dar_store_summary',
          component: () => import('@/views/reports/KagSalesDarStoreSummary'),
          meta: { requiresAuth: false },
        },
        {
          name: 'KAG Service DAR',
          path: '/reports/kag_service_dar',
          component: () => import('@/views/reports/KagServiceDar'),
          meta: { requiresAuth: false },
        },
      ],
    },
    {
      path: '/',
      component: () => import('@/views/dashboard/PrintableIndex'),
      children: [
        {
          name: 'AC Password Reset',
          path: '/password_reset',
          component: () => import('@/views/PasswordReset'),
          meta: { requiresAuth: false },
        },
        {
          name: 'AC Accpet Invite',
          path: '/ac_accept_invite',
          component: () => import('@/views/AcceptInvite'),
          meta: { requiresAuth: false },
        },
      ],
    },
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      meta: { requiresAuth: true },
      children: [
        {
          name: 'DMS Sales Search',
          path: '/dms_sales/dms_sales_search',
          component: () => import('@/views/dashboard/DmsSalesSearch'),
          meta: { requiresAuth: true },
        },
        {
          name: 'Vehicle Sales With No Service',
          path: '/dms_sales/purchased_no_serviced_records',
          component: () => import('@/views/dashboard/PurchasedButNoServiceRecordsReport'),
          meta: { requiresAuth: true },
        },
        {
          name: 'DMS Service',
          path: '/dms_service/service_records',
          component: () => import('@/views/dashboard/DmsService'),
          meta: { requiresAuth: true },
        },
        {
          name: 'Not Recently Serviced',
          path: '/dms_service/not_recently_serviced',
          component: () => import('@/views/dashboard/NotRecentlyServiced'),
          meta: { requiresAuth: true },
        },
        {
          name: 'Declined Work',
          path: '/dms_service/declined_work',
          component: () => import('@/views/dashboard/DeclinedWork'),
          meta: { requiresAuth: true },
        },
        {
          name: 'Campaigns',
          path: '/campaigns',
          component: () => import('@/views/dashboard/MailCampaigns'),
          meta: { requiresAuth: true },
        },
        {
          name: 'Campaign Files',
          path: '/campaigns/:mail_campaign_id/files',
          component: () => import('@/views/dashboard/MailCampaignFiles'),
          meta: { requiresAuth: true },
        },
        {
          name: 'Campaign Matchback Results',
          path: '/mail_campaigns/:mail_campaign_id/matchback_results',
          component: () => import('@/views/dashboard/CampaignMatchbackResults'),
          meta: { requiresAuth: true },
        },
        {
          name: 'SaleMatchbacks',
          path: '/dms_sales/sales_matchbacks',
          component: () => import('@/views/dashboard/SalesMatchback'),
          meta: { requiresAuth: true },
        },
        {
          name: 'Vehicles',
          path: 'vehicles/in_stock',
          component: () => import('@/views/dashboard/Vehicles'),
          meta: { requiresAuth: true },
        },
        {
          name: 'Leads',
          path: '/vendors/:vendor_id/leads',
          component: () => import('@/views/dashboard/Leads'),
          meta: { requiresAuth: true },
        },
        {
          name: 'Calls',
          path: '/vendors/:vendor_id/calls',
          component: () => import('@/views/dashboard/Calls'),
          meta: { requiresAuth: true },
        },
        {
          name: 'Sold Vehicles',
          path: 'sold_vehicles',
          component: () => import('@/views/dashboard/SoldVehicles'),
          meta: { requiresAuth: true },
        },
        {
          name: 'Vendors',
          path: 'vendors',
          component: () => import('@/views/dashboard/Vendors'),
          meta: { requiresAuth: true },
        },
        {
          name: 'New Vendor',
          path: 'new_vendor',
          component: () => import('@/views/dashboard/NewVendor'),
          meta: { requiresAuth: true },
        },
        {
          name: 'Upload Files',
          path: 'upload_files',
          component: () => import('@/views/dashboard/UploadFiles'),
          meta: { requiresAuth: true },
        },
        {
          name: 'Open AI',
          path: 'open_ai',
          component: () => import('@/views/dashboard/OpenAi'),
          meta: { requiresAuth: true },
        },
        {
          name: 'iMos Campaigns',
          path: 'imos_campaigns',
          component: () => import('@/views/dashboard/ImosCampaigns'),
          meta: { requiresAuth: true },
        },        
        {
          name: 'Google My Business',
          path: 'gmb',
          component: () => import('@/views/dashboard/GmbAllProfiles'),
          meta: { requiresAuth: true },
        },    
        {
          name: 'Mailers',
          path: 'mailers',
          component: () => import('@/views/dashboard/ScheduledReports'),
          meta: { requiresAuth: true },
        },             
        {
          name: 'Mailer Files',
          path: 'mailers/:report_id/report_files',
          component: () => import('@/views/dashboard/ScheduledReportFiles'),
          meta: { requiresAuth: true },
        },             
        {
          name: 'Edit Mailer',
          path: 'mailers/:report_id/edit',
          component: () => import('@/views/dashboard/EditScheduledReport'),
          meta: { requiresAuth: true },
        },      
        {
          name: 'Marketing Calendar',
          path: 'marketing_calendar',
          component: () => import('@/views/dashboard/MarketingCalendar'),
          meta: { requiresAuth: true },
        },           
        {
          name: 'Vendor Deals',
          path: '/vendors/:vendor_id/deals',
          component: () => import('@/views/dashboard/VendorDeals'),
          meta: { requiresAuth: true },
        },
        {
          name: 'Home',
          path: 'home',
          component: () => import('@/views/dashboard/Home'),
          meta: { requiresAuth: true },
        },
        {
          name: 'Vehicle Groups',
          path: '/vehicles/vehicle_groups',
          component: () => import('@/views/dashboard/VehicleGroups'),
          meta: { requiresAuth: true },
        },
        {
          name: 'Vehicle Leads',
          path: '/vehicles/:vehicle_id/leads',
          component: () => import('@/views/dashboard/VehicleLeads'),
          meta: { requiresAuth: true },
        },
        {
          name: 'Vehicle Price Changes',
          path: '/vehicles/:vehicle_id/price_changes',
          component: () => import('@/views/dashboard/VehiclePriceChanges'),
          meta: { requiresAuth: true },
        },
        {
          name: 'Vehicle Details',
          path: '/vehicles/:vehicle_id',
          component: () => import('@/views/dashboard/VehicleDetails'),
          meta: { requiresAuth: true },
        },
        {
          name: 'Settings',
          path: '/settings',
          component: () => import('@/views/dashboard/Settings'),
          meta: { requiresAuth: true },
        },
        {
          name: 'Extra',
          path: '/extra',
          component: () => import('@/views/dashboard/Extra'),
          meta: { requiresAuth: true },
        },
        {
          name: 'DAR Status',
          path: '/dar_status',
          component: () => import('@/views/dashboard/DarStatus'),
          meta: { requiresAuth: true },
        },
        {
          name: 'Account Status',
          path: '/admin/account_statuses',
          component: () => import('@/views/dashboard/AdminAccountStatus'),
          meta: { requiresAuth: true },
        },
        {
          name: 'Manage DNC Lists',
          path: '/admin/manage_dnc',
          component: () => import('@/views/dashboard/ManageDnc'),
          meta: { requiresAuth: true },
        },        
        {
          name: 'New Mission',
          path: '/attempting_contact/missions/new',
          component: () => import('@/views/dashboard/ChatovateCampaign'),
          meta: { requiresAuth: true },
        },
        {
          name: 'Edit Mission',
          path: '/attempting_contact/missions/:campaign_id/',
          component: () => import('@/views/dashboard/ChatovateCampaign'),
          meta: { requiresAuth: true },
        },
        {
          name: 'Missions',
          path: '/attempting_contact/missions/',
          component: () => import('@/views/dashboard/ChatovateCampaigns'),
          meta: { requiresAuth: true },
        },
        {
          name: 'Google Authorization',
          path: '/google_confirmation',
          component: () => import('@/views/dashboard/GoogleConfirmation'),
          meta: { requiresAuth: true },
        },
        {
          name: 'Customers',
          path: '/attempting_contact/customers/:campaign_id/',
          component: () => import('@/views/dashboard/ChatovateCustomers'),
          meta: { requiresAuth: true },
        },
        {
          name: 'Recent Customer Messages',
          path: '/attempting_contact/recent_customer_messages/',
          component: () => import('@/views/dashboard/RecentCustomerMessages'),
          meta: { requiresAuth: true },
        },        
        {
          name: 'Matchbacks',
          path: '/attempting_contact/matchbacks/:campaign_id/',
          component: () => import('@/views/dashboard/ChatovateMatchbackResults'),
          meta: { requiresAuth: true },
        },
        {
          name: 'Customer',
          path: '/attempting_contact/campaigns/:campaign_id/customers/:customer_id/',
          component: () => import('@/views/dashboard/ChatovateCustomer'),
          meta: { requiresAuth: true },
        },
        {
          name: 'Recent Appointments Set',
          path: '/attempting_contact/appointments/',
          component: () => import('@/views/dashboard/ChatovateAppointments'),
          meta: { requiresAuth: true },
        },
        {
          name: 'Recent Lost',
          path: '/attempting_contact/lost/',
          component: () => import('@/views/dashboard/ChatovateLost'),
          meta: { requiresAuth: true },
        },
        {
          name: 'Waiting On Us',
          path: '/attempting_contact/waiting_on_us/',
          component: () => import('@/views/dashboard/ChatovateWaitingOnUs'),
          meta: { requiresAuth: true },
        },
        {
          name: 'Waiting On Them',
          path: '/attempting_contact/waiting_on_them/',
          component: () => import('@/views/dashboard/ChatovateWaitingOnThem'),
          meta: { requiresAuth: true },
        },
        {
          name: 'User Profile',
          path: '/attempting_contact/profile/',
          component: () => import('@/views/dashboard/pages/UserProfile'),
          meta: { requiresAuth: true },
        },
        {
          name: 'Manage Users',
          path: '/attempting_contact/manage_users/',
          component: () => import('@/views/dashboard/ChatovateManageUsers'),
          meta: { requiresAuth: true },
        },
        {
          name: 'Edit User',
          path: '/attempting_contact/edit_user/:user_id',
          component: () => import('@/views/dashboard/ChatovateEditUser/'),
          meta: { requiresAuth: true },
        },
        {
          name: 'DMS Import Batches',
          path: '/attempting_contact/dms_import_batches',
          component: () => import('@/views/dashboard/DmsImportBatches/'),
          meta: { requiresAuth: true },
        },        
        {
          name: 'DMS Import Batches',
          path: '/dms_import_batches',
          component: () => import('@/views/dashboard/DmsImportBatches/'),
          meta: { requiresAuth: true },
        },    
        {
          name: 'ASC Dashboard',
          path: '/asc',
          component: () => import('@/views/dashboard/Looker'),
          meta: { requiresAuth: true },
        },        


      ],
    },
    {
      path: '*',
      component: () => import('@/views/pages/Index'),
      children: [
        {
          name: '404 Error',
          path: '',
          component: () => import('@/views/pages/Error'),
        },
      ],
    },
  ],
})

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('user')

  if (to.matched.some(record => record.meta.requiresAuth) && !loggedIn) {
    next('/login?path=' + to.path)
  }
  if (to.path === '/') {
    var host = window.location.host
    if (new RegExp('dealerinsights').test(host)) {
      next('/home')
    }
    if (new RegExp('attemptingcontact').test(host)) {
      next('/attempting_contact/missions/')
    }
    if (new RegExp('localhost').test(host)) {
      next('/attempting_contact/missions/')
    }
  }
  if (to.matched.some(record => record.name === 'Login') && loggedIn) {
    store.dispatch('logout')
    next('/login')
  }
  next()
})
export default router
