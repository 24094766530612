import axios from 'axios'

export default {
  getAlertSettings (data) {
    return axios.get('/alert_settings')
  },
  saveAlertSettings (data) {
    return axios.post('/alert_settings', data)
  },
  createNewAccount (data) {
    return axios.post('/accounts', data)
  },
  googleConfirmation (code) {
    return axios.get('/google_confirmation?code=' + code)
  },
  getGoogleWebsites () {
    return axios.get('/api/v1/get_websites')
  },
  setGoogleWebsite (websiteId) {
    return axios.post('/api/v1/update_account_website_id?website_id=' + websiteId)
  },
  updateWebsiteProvider (provider) {
    return axios.post('/api/v1/update_website_provider?provider=' + provider)
  },
  getWebsiteProvider (property_id) {
    return axios.get('/api/v1/get_website_provider?property_id=' + property_id)
  },
  getSupportedWebsiteProviders () {
    return axios.get('/api/v1/supported_website_providers')
  },
  getAccountSetup () {
    return axios.get('/api/v1/account_setup')
  },
}
