export const navigationMixin = {
  methods: {
    goBack: function () {
      return this.$router.go(-1)
    },
    defaultStartDate () {
      if (this.$route.query.start_date) {
        return this.$route.query.start_date
      } else {
        return new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().substring(0, 10)
      }
    },
    defaultEndDate () {
      if (this.$route.query.end_date) {
        return this.$route.query.end_date
      } else {
        return new Date().toISOString().substring(0, 10)
      }
    },
  },
}
