import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import AccountService from '@/services/AccountService.js'
import Vuetify from './plugins/vuetify'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: null,
    drawer: null,
    user: null,
  },
  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    SET_SCRIM (state, payload) {
      state.barColor = payload
    },
    UPDATE_USER_DATA (state, userData) {
      state.user = userData
      localStorage.setItem('user', JSON.stringify(userData))
    },
    SET_USER_DATA (state, userData) {
      state.user = userData
      Vue.set(state.user, 'report_data', {})
      Vue.set(state.user, 'account_setup_data', {})
      Vue.set(state.user, 'chatovate_inbound_call', {})
      Vue.set(state.user, 'chatovate_sms', {})
      Vue.set(state.user, 'chatovate_sent_sms', {})
      Vue.set(state.user, 'release', {})
      Vue.set(state.user, 'appName', {})
      Vue.set(state.user, 'apptsSet', {})
      Vue.set(state.user, 'apptsLost', {})
      Vue.set(state.user, 'vehicleDescription', {})
      localStorage.setItem('user', JSON.stringify(userData))
      axios.defaults.headers.common.Authorization = `Bearer ${userData.token}`
      axios.interceptors.request.use(function (config) {
        const aid = state.user.account_id
        config.headers.aid = aid
        return config
      })
      var baseUrl = process.env.NODE_ENV === 'development' ? 'ws://localhost:3000/cable' : 'wss://dealer-insights-api.herokuapp.com/cable'
      var websocketUrl = baseUrl + '?token=' + state.user.token
      this.$cable.connection.connect(websocketUrl)
      this.$cable.subscribe({
        channel: 'ReportChannel',
      })
      this.$cable.subscribe({
        channel: 'AccountSetupChannel',
      })
      this.$cable.subscribe({
        channel: 'ChatovateInboundCallChannel',
      })      
      this.$cable.subscribe({
        channel: 'ChatovateSmsChannel',
      })
      this.$cable.subscribe({
        channel: 'ReleaseChannel',
      })
      this.$cable.subscribe({
        channel: 'ChatovateSentSmsChannel',
      })
      this.$cable.subscribe({
        channel: 'OpenAiDescriptionChannel',
      })


      // window.Appcues.identify(
      //   userData.uuid, // unique, required
      //   {
      //     // recommended (optional) properties
      //     firstName: userData.first_name,
      //     lastName: userData.last_name,      
      //     companyName: userData.account_name,
      //     email: userData.email
      //   }
      // );

      window.userflow.identify(userData.uuid, {
        name: userData.first_name + ' ' + userData.last_name,
        email: userData.email,
        companyName: userData.account_name,
        // signed_up_at: 'USER_SIGNED_UP_AT'
      })
    },
    CLEAR_USER_DATA () {
      localStorage.removeItem('user')
    },
    SET_ACCOUNT_ID (state, accountId) {
      state.user.account_id = accountId
      state.user.account_name = state.user.accounts.find(
        account => account.id === accountId,
      ).name
      AccountService.getAlertSettings()
        .then(response => {
          state.user.alert_settings = response.data
          localStorage.setItem('user', JSON.stringify(state.user))
        })
    },
    SET_REPORT_DATA (state, reportData) {
      state.user.report_data = reportData
    },
    SET_ACCOUNT_SETUP_DATA (state, accountSetupData) {
      state.user.account_setup_data = accountSetupData
    },
    SET_GOOGLE_WEBSITE_ID (state, websiteId) {
      state.user.google_website_id = websiteId
      localStorage.setItem('user', JSON.stringify(state.user))
    },
    SET_CHATOVATE_INBOUND_CALL (state, chatovateInboundCall) {
      state.user.chatovate_inbound_call = chatovateInboundCall
    },    
    SET_CHATOVATE_SMS (state, chatovateSms) {
      state.user.chatovate_sms = chatovateSms
    },
    SET_CHATOVATE_SENT_SMS (state, chatovateSms) {
      state.user.chatovate_sent_sms = chatovateSms
    },
    SET_RELEASE (state, release) {
      state.user.release = release
    },
    SET_APP_NAME (state, appName) {
      state.user.appName = appName
    },
    SET_APPTS_SET (state, apptsSet) {
      state.user.apptsSet = apptsSet
    },
    SET_APPTS_LOST (state, apptsLost) {
      state.user.apptsLost = apptsLost
    },
    SET_VEHICLE_DESCRIPTION (state, vehicleDescription) {
      state.user.vehicleDescription = vehicleDescription
    },
  },
  actions: {
    login ({ commit }, credentials) {
      return axios.post('/sessions', credentials).then(({ data }) => {
        commit('SET_USER_DATA', data)
      })
    },
    logout ({ commit }) {
      commit('CLEAR_USER_DATA')
    },
    set_account_id ({ commit }, accountId) {
      commit('SET_ACCOUNT_ID', accountId)
    },
    set_report_data ({ commit }, reportData) {
      commit('SET_REPORT_DATA', reportData)
    },
    set_account_setup_data ({ commit }, accountSetupData) {
      commit('SET_ACCOUNT_SETUP_DATA', accountSetupData)
    },
    set_google_website_id ({ commit }, websiteId) {
      commit('SET_GOOGLE_WEBSITE_ID', websiteId)
    },
    set_chatovate_sms ({ commit }, chatovateSms) {
      commit('SET_CHATOVATE_SMS', chatovateSms)
      if (Notification.permission === 'granted') {
        // If it's okay let's create a notification
        // eslint-disable-next-line no-new
        new Notification(chatovateSms.message)
      }
    },
    set_chatovate_sent_sms ({ commit }, chatovateSms) {
      commit('SET_CHATOVATE_SENT_SMS', chatovateSms)
    },
    set_chatovate_inbound_call ({ commit }, chatovateInboundCall) {
      commit('SET_CHATOVATE_INBOUND_CALL', chatovateInboundCall)
    },    
    set_release ({ commit }, release) {
      commit('SET_RELEASE', release)
    },
    set_appName ({ commit }, appName) {
      commit('SET_APP_NAME', appName)
      if (appName === 'insights') {
        Vuetify.framework.theme.themes.light.primary = Vuetify.framework.theme.defaults.light.primary
        Vuetify.framework.theme.themes.light.secondary = Vuetify.framework.theme.defaults.light.secondary
      }
      if (appName === 'contact') {
        Vuetify.framework.theme.themes.light.primary = '#90C74C'
        Vuetify.framework.theme.themes.light.secondary = '#FF4081'

        // Vuetify.framework.theme.themes.light.primary = '#90C74C'
        // Vuetify.framework.theme.themes.light.secondary = '#FF0056'
      }
    },
    set_appts_set ({ commit }, apptsSet) {
      commit('SET_APPTS_SET', apptsSet)
    },
    set_appts_lost ({ commit }, apptsLost) {
      commit('SET_APPTS_LOST', apptsLost)
    },
    set_vehicle_description ({ commit }, vehicleDescription) {
      commit('SET_VEHICLE_DESCRIPTION', vehicleDescription)
    }
  },
  getters: {
    loggedIn (state) {
      return !!state.user
    },
    user (state) {
      return state.user
    },
    accounts (state) {
      return state.user.accounts
    },
    accountId (state) {
      return state.user.account_id
    },
    accountName (state) {
      return state.user.account_name
    },
    alertSettings (state) {
      return state.user.alert_settings
    },
    permissions (state) {
      return state.user.permissions
    },
    dealerGroup (state) {
      return state.user.dealer_group
    },
    dealerGroupId (state) {
      return state.user.dealer_group_id
    },
    reportData (state) {
      return state.user.report_data
    },
    di_admin (state) {
      return state.user.di_admin
    },
    googleWebsiteId (state) {
      return state.user.google_website_id
    },
    accountSetupData (state) {
      return state.user.account_setup_data
    },
    chatovateInboundCall (state) {
      return state.user.chatovate_inbound_call
    },
    chatovateSms (state) {
      return state.user.chatovate_sms
    },
    chatovateSentSms (state) {
      return state.user.chatovate_sent_sms
    },
    release (state) {
      return state.user.release
    },
    appName (state) {
      return state.user.appName
    },
    apptsSet (state) {
      return state.user.apptsSet
    },
    apptsLost (state) {
      return state.user.apptsLost
    },
    vehicleDescription (state) {
      return state.user.vehicleDescription
    }
  },
  modules: {},
})
